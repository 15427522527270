html {
  height: 100%;
  font-size: 15px;
  box-sizing: border-box;
  font-family: AvenirNextLTPro, Arial, serif;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-height: 100%;
  min-height: 0;
}

body,
main,
#root {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-height: 0;
  overflow: auto;
  padding: 0;
  margin: 0;
  font-size: 16px;
  max-height: 100%;
  overflow: hidden;
}
